@import "~antd/dist/antd.less";
@import "./variables.less";

.App {
  background: @warning-color;
}

.site-logo {
  height: 64px;
  line-height: 64px;
  text-align: center;
}

.link {
  color: @link-color;
  cursor: pointer;
}

.ant-layout {
  min-height: 100vh;
}

.ant-space-item {
  padding: 3px;
}

.align-center {
  text-align: center;
}

.top-margin {
  margin-top: 1rem;
}

.form-login-container {
  padding: 16px 32px;
  background-color: @greyish-white;
  border-radius: 4px;
}
.form-prescription-container {
  padding: 16px 32px;
  border-radius: 4px;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.site-layout .site-layout-background {
  background: white;
  position: relative;
}

.logo-container {
  margin: 10px auto auto;
  padding: 32px;
  border-radius: 50%;
  background-color: #414042;
  height: 90px;
  width: 90px;
}

.ant-layout-header {
  height: 64px;
  padding: 0 18px;
  background: rgb(170, 170, 170);
  line-height: 64px;
}

.site-header {
  display: flex;
  justify-content: space-between;
}

.call_icon {
  margin-left: 8px;
  font-size: 20px;
}

.ant-layout-sider {
  position: relative;
  min-width: 0;
  background: white;
  transition: all 0.2s;
}

.sidebar-container {
  position: relative;

  .sidebar-trigger {
    background-color: @primary-color;
    color: @greyish-white;
    line-height: 40px;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(100%);
  }
}

.synthetic-id-icon-container {
  color: inherit;
  font-size: 1rem;

  .synthetic-id-icon {
    transition: transform 200ms ease-in;
  }

  .synthetic-id-icon:hover {
    transform: scale(1.25) translate(2px, -2px);
  }
}

// override
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-item {
  margin-top: 0;
}

.scrollable {
  height: calc(100vh - 160px);
  overflow-y: scroll;

  .ant-list-header {
    background-color: @greyish-white;
    position: sticky;
    top: 0;
    z-index: 999;
  }
}

.case-reminder {
  height: 300px;
}

.lead-details {
  .lead-info {
    border: 1px solid @greyish-white;
    border-radius: 2px;
    padding: 16px 24px;
  }

  .lead-details-tabs {
    border-top: 1px solid @light-grey;
    border-right: 1px solid @light-grey;
    border-radius: 2px;
    padding: 16px 24px;

    .geturl-container {
      position: sticky;
      top: 0;
      padding: 4px 1rem;
      z-index: 1;
      background-color: #f1f1f1;
    }
  }

  .lead-image {
    .ant-image-img {
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .recent-comment {
    height: 324px;
    overflow-y: scroll;
    width: 100%;

    .ant-list-header {
      background-color: #fcfcfc;
      position: sticky;
      top: 0;
      z-index: 1;
    }

    .delete-btn {
      width: 32px;
    }
  }

  .comment-section {
    .ant-row:nth-child(1) {
      flex: 10;
      padding: 16px 0;
      margin: 0 8px 0 0;
    }

    .ant-row:nth-child(2) {
      flex: auto;
      padding: 16px 0;
      margin: 0;
    }

    .ant-row:nth-child(3) {
      flex: auto;
      padding: 16px 0;
      margin: 0 0 0 8px;
    }
  }
}

.write-prescription {
  background-color: @primary-color;
  cursor: pointer;
  position: fixed;
  bottom: 1rem;
  right: 2rem;
  z-index: 99;
}
.order-data-card-body {
  width: 19rem;
  font-weight: bold;
  font-size: 40px;
}

.order_link_form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align-last: start;
}

.order-filter > .ant-form-item {
  margin-right: 0.25rem;
}

.image {
  .ant-image-img {
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.text-success {
  color: @success-color;
}
.text-success:hover,
.text-success:active,
.text-success:focus {
  color: hsl(100, 77%, 34%);
}
.text-danger {
  color: @error-color;
}
.text-danger:hover,
.text-danger:active,
.text-danger:focus {
  color: hsl(357, 91%, 45%);
}

.bg-transparent {
  background-color: transparent;
}
.bg-success {
  background-color: @success-color;
}
.bg-success-50 {
  background-color: hsl(100, 77%, 97%);
  border-color: hsl(100, 77%, 97%);
}
.bg-success-100 {
  background-color: hsl(100, 77%, 92%);
  border-color: hsl(100, 77%, 92%);
}
.bg-danger {
  background-color: @error-color;
}
.bg-danger-50 {
  background-color: hsl(357, 91%, 97%);
  border-color: hsl(357, 91%, 97%);
}

.block {
  display: block;
}

.grid {
  display: grid;
}
.admin__sales__dashboard-grid {
  grid-template-columns: repeat(5, minmax(15rem, 1fr));
}
.admin__post_sales__dashboard-grid {
  grid-template-columns: repeat(4, minmax(15rem, 1fr));
}
.ticket__attchments {
  grid-template-columns: repeat(3, minmax(96px, 1fr));
}
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.flex-1 {
  flex: 1 1 0%;
}

.items-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}

.gap-1 {
  gap: 0.25rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-3 {
  gap: 0.75rem;
}
.gap-4 {
  gap: 1rem;
}

.overflow-y-auto {
  overflow-y: auto;
}
.overflow-auto {
  overflow: auto;
}

.h-3 {
  height: 0.75rem;
}
.h-full {
  height: 100%;
}
.h-max {
  height: max-content;
}

.w-3 {
  width: 0.75rem;
}
.w-full {
  width: 100%;
}
.w-half {
  width: 50%;
}
.w-max {
  width: max-content;
}
.p-0 {
  padding: 0;
}
.p-2 {
  padding: 0.5rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.px-2 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.px-8 {
  padding-right: 1rem;
  padding-left: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.pt-4 {
  padding-top: 1rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.m-4 {
  margin: 1rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-4 {
  margin-right: 1rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-4 {
  margin-left: 1rem;
}
.ml-8 {
  margin-left: 2rem;
}
.ml-16 {
  margin-left: 4rem;
}
.mt-px {
  margin-top: 1px;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-8 {
  margin-top: 2rem;
}
.mt-16 {
  margin-top: 4rem;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-8 {
  margin-bottom: 2rem;
}

.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.justify-start {
  justify-content: flex-start;
}

.justify-items-end {
  justify-items: end;
}
.justify-items-center {
  justify-items: center;
}

.capitalize {
  text-transform: capitalize;
}
.uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.pointer {
  cursor: pointer;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.top-0 {
  top: 0;
}
.fw-400 {
  font-weight: 400;
}
.br-1 {
  border-radius: 4px;
}
.zIndex-top {
  z-index: 1;
}
// custome code
.backBtn {
  font-family: "Rubik", sans-serif;
}

.sticky {
  position: sticky;
}

// ant-design
.slick-slide div {
  width: 250px;
}
.ant-image-mask-info {
  text-align-last: center !important;
}
.dltImgIcon {
  display: none;
  position: absolute;
  z-index: 99999999;
  top: -10px;
  right: -10px;
  padding: 8px 12px;
  background: #fff;
  border-radius: 100px;
  width: 40px !important;
  height: 40px;
}
.carouselCon:hover .dltImgIcon {
  display: block !important;
}
.anticon-phone {
  border: 1px solid;
  padding: 7px;
  border-radius: 30px;
}
.ant-form label {
  width: 100%;
  font-size: 20px;
  color: #000;
  font-weight: 400;
}

.activeTag {
  width: 450px;
  height: 70px;
  font-size: 22px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  padding: 7px 12px;
  background: @primary-color;
  color: #fcfcfc;
  border-radius: 9px;
  cursor: pointer;
}

.inactiveTag {
  width: 450px;
  height: 70px;
  font-size: 22px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  padding: 7px 12px;
  cursor: pointer;
  color: #73767d;
  background: @disable-color;
  border-radius: 9px;
}

.activesTag {
  width: 20px;
  height: 5px;
  font-size: 22px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  padding: 7px 12px;
  background: @primary-color;
  color: #fcfcfc;
  border-radius: 9px;
  cursor: pointer;
}

.inactivesTag {
  width: 20px;
  height: 5px;
  font-size: 22px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  padding: 7px 12px;
  cursor: pointer;
  color: #73767d;
  background: @disable-color;
  border-radius: 9px;
}
.trayalogo {
  margin-top: 10px;
  margin-left: 20px;
  padding: 20px;
}
.userDetailContainer {
  width: 100%;
  height: 60vh;
  background-color: white;
  text-align: center;
  margin-top: 75px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 25px;
}
.orderContainer {
  margin-top: 38px;
}

.radioBar {
  width: 431px;
  height: 0px;
  opacity: 0.1;
  border: 0.5px solid #000000;
}

.btnContainer {
  display: flex;
  align-items: flex-end;
  width: 32%;
  justify-content: space-between;
}

.commentField {
  width: 75%;
  color: #000000;
  border-bottom: 2px solid #e6e6e6;
  border-radius: 0px;
}
.commentField:focus {
  width: 75%;
  border-bottom: 2px solid #e6e6e6;
  border-radius: 0px;
}
.nextBtn {
  display: flex;
  align-items: flex-end;
  width: 85%;
  justify-content: center;
}
.header {
  font-family: "Nunito Sans";
  font-size: 22px;
  font-weight: 700;
  color: #414042;
}
.sub-header {
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 400;
  opacity: 0.7;
  color: #9ca3ad;
  margin-top: 1px;
}
.cardView {
  width: 97%;
  height: auto;
  background: #fff;
  box-shadow: 3px 2px 9px 0 rgba(0, 0, 0, 0.08);
  border-radius: 7px;
  padding: 10px 15px;
  text-align: center;
  border: 1px solid #b7d340;
}
.container {
  width: 97%;
  background-color: white;
  text-align: center;
  margin-top: 100px;
}
.container-4 {
  width: 97%;
  background-color: white;
  text-align: center;
  margin-top: 25%;
}
.card {
  width: 85%;
  height: auto;
  background: #fff;
  box-shadow: 3px 2px 9px 0 rgba(0, 0, 0, 0.08);
  border-radius: 7px;
  padding: 5px 5px;
  text-align: center;
  margin-left: 0px;
  border: 1px solid #e5e5e5;
}
.bar {
  width: 300px;
  height: 0px;
  border: 0.5px solid #b7d340;
  // margin-left: 570px;
  margin: 0 auto;
  margin-top: 30px;
}
.progressBarContainer {
  background-color: #e2e2e2;
  width: 235px;
  height: 12px;
  border-radius: 11px;
  margin-left: 15px;
  margin-top: 5px;
}
.progressBar {
  height: 11.82px;
  background-color: #b7d340;
  color: white;
  padding: 1%;
  text-align: right;
  font-size: 20px;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 11.8238px;
}
.ab {
  display: inline-flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  flex-direction: column;
  align-items: center;
  border-radius: 0.75rem;
  border-width: 1px;
  cursor: pointer;
}

button:disabled {
  background-color: #9ca3af;
  color: #e6e7e8;
  cursor: not-allowed;
  border: none;
}
.bc {
  display: inline-flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex-direction: column;
  align-items: center;
  align-self: center;
  border-radius: 0.75rem;
  border-width: 1px;
  background-color: white;
  cursor: pointer;
}

.p-color {
  color: #ffffff;
  background-color: #9bba70;
}
.x-color {
  color: #4d4d4d;
  background-color: white;
  border-color: #9ca3af;
}
.y-color {
  border-color: #9ca3af;
}
.progressTagContainer {
  display: flex;
  justify-content: space-between;
  width: 435px;
}
.userContainer {
  width: 910px;
  height: 60px;
  margin-top: 5px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}
.rootCauseContainer {
  width: 910px;
  height: 120px;
  padding: 20px;
}
.userCardbar {
  width: 909px;
  height: 0px;
  border: 0.2px solid #ebf1e2;
  margin-left: -20px;
  margin-top: 5px;
}
.userDetailBox {
  width: 400px;
  height: 197px;
  border-radius: 0px;
  border: 1px solid #b7d340;
}
.diagnosisContainer {
  width: 427.5px;
  height: 120px;
  padding: 20px;
  border-radius: 0px 0px 9px 9px;
}
.diagnosisBar {
  width: 348px;
  height: 0px;
  border: 0.2px solid #ebf1e2;
  margin-left: -20px;
  margin-top: 5px;
}
.diagnosis-1 {
  font-family: Nunito Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  width: 110px;
  height: 30px;
  color: #414042;
  opacity: 0.7;
  margin-left: -6px;
  margin-top: -4%;
  text-align: left;
}
.diagnosis-2 {
  font-family: Nunito Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  width: 110px;
  height: 30px;
  color: #414042;
  opacity: 0.7;
  margin-left: -8px;
  margin-top: 5%;
  text-align: left;
}
.diagnosisDetails-1 {
  font-family: Nunito Sans, sans-serif;
  width: 300px;
  height: 21px;
  color: #000000;
  margin-top: -4%;
  margin-left: 20px;
  text-align: left;
}
.diagnosisDetails-2 {
  font-family: Nunito Sans, sans-serif;
  width: 300px;
  height: 21px;
  color: #000000;
  margin-top: 6%;
  margin-left: 17px;
  text-align: left;
}
.diagnosisBar-1 {
  width: 0px;
  height: 32px;
  margin-left: 20px;
  margin-top: -2%;
  border: 0.5px solid #ebf1e2;
}
.diagnosisBar-2 {
  width: 0px;
  height: 50px;
  margin-left: 21px;
  margin-top: 7%;
  border: 0.5px solid #ebf1e2;
}
.userContainer-1 {
  width: 420px;
  height: 60px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}
.progressBarText {
  font-family: Nunito Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: #414043;
  margin-left: 125px;
}
.slotBtnText {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
}

.tagContainer {
  width: 715px;
  height: auto;
  background: #fff;
  border-radius: 7px;
  padding: 0px 55px;
}
.introPageContainer {
  width: 717px;
  height: auto;
  background: #fff;
  border-radius: 7px;
  padding: 0px 58px;
  margin-left: 22px;
}
.mainContainer {
  width: 50%;
  height: auto;
  margin: 0 auto;
}
.ant-btn > .anticon + span {
  margin-left: 0px;
}
.finalBtn {
  width: 30%;
  height: 45px;
  margin-top: 10px;
  margin-bottom: 0px;
  background-color: #b7d340;
  font-size: 18px;
  color: white;
  font-weight: 600;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.finalBtn:hover,
.finalBtn:visited,
.finalBtn:active,
.finalBtn:link {
  background-color: #b7d340;
  color: white;
}
.bookingBtn {
  width: 30%;
  height: 45px;
  margin-top: 20px;
  margin-bottom: 15px;
  background-color: #b7d340;
  font-size: 18px;
  color: white;
  font-weight: 600;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.bookingBtn:hover,
.bookingBtn:visited,
.bookingBtn:active,
.bookingBtn:link {
  background-color: #b7d340;
  color: white;
}
.resultBtn {
  font-size: 20px;
    border-radius: 5px;
    box-shadow: none;
    width:300px;
    height: 45px;
}
.previousBtn {
  background-color: white;
  border: none;
  cursor: pointer;
  box-shadow: none;
}
@media screen and (max-width: 480px) {
  .ant-form label {
    width: 100%;
    font-size: 20px;
    color: #000;
    font-weight: 400;
  }
  .activeTag {
    width: 32%;
    height: 60px;
    margin-left: 0px;
    font-size: 10px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    padding: 22px 3px;
    background: @primary-color;
    color: #fcfcfc;
    border-radius: 9px;
    cursor: pointer;
  }
  .inactiveTag {
    width: 32%;
    height: 60px;
    margin-left: 0px;
    margin-right: 0px;
    font-size: 10px;
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    padding: 22px 3px;
    cursor: pointer;
    color: #73767d;
    background: @disable-color;
    border-radius: 9px;
  }
  .progressBarContainer {
    background-color: #e2e2e2;
    width: 235px;
    height: 12px;
    border-radius: 11px;
    margin-left: 15px;
    margin-top: 5px;
  }
  .progressTagContainer-1 {
    display: flex;
    justify-content: space-between;
    width: 32%;
  }
  .activeTag-1 {
    width: 105px;
    font-size: 22px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    padding: 3.5px;
    background: @primary-color;
    color: #fcfcfc;
    border-radius: 9px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-right: 5px;
  }
  .inactiveTag-1 {
    width: 105px;
    font-size: 22px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    padding: 3.5px;
    cursor: pointer;
    color: #73767d;
    background: @disable-color;
    border-radius: 9px;
    margin-bottom: 20px;
    margin-right: 5px;
  }
  .progressTagContainer-2 {
    display: flex;
    justify-content: space-between;
    width: 32%;
    margin-left: 35%;
  }
  .activeTag-2 {
    width: 105px;
    font-size: 22px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    padding: 3.5px;
    background: @primary-color;
    color: #fcfcfc;
    border-radius: 9px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-right: 5px;
  }
  .inactiveTag-2 {
    width: 105px;
    font-size: 22px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    padding: 3.5px;
    cursor: pointer;
    color: #73767d;
    background: @disable-color;
    border-radius: 9px;
    margin-bottom: 20px;
    margin-right: 5px;
  }
  .progressTagContainer-3 {
    display: flex;
    justify-content: space-between;
    width: 32%;
    margin-left: 69%;
  }
  .activeTag-3 {
    width: 105px;
    font-size: 22px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    padding: 3.5px;
    background: @primary-color;
    color: #fcfcfc;
    border-radius: 9px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-right: 5px;
  }
  .inactiveTag-3 {
    width: 105px;
    font-size: 22px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    padding: 3.5px;
    cursor: pointer;
    color: #73767d;
    background: @disable-color;
    border-radius: 9px;
    margin-bottom: 20px;
    margin-right: 5px;
  }
  .tagContainer {
    width: 100%;
    height: auto;
    background: #fff;
    border-radius: 7px;
    padding: 0px 0px;
  }
  .introPageContainer {
    width: 320px;
    height: auto;
    background: #fff;
    border-radius: 7px;
    padding: 0px 0px;
    margin-left: 0px;
  }
  .trayalogo {
    margin-top: 10px;
    margin-left: 0px;
    padding: 20px;
  }
  .userDetailContainer {
    width: 100%;
    height: 60vh;
    background-color: white;
    text-align: center;
    margin-top: 75px;
    padding-top: 25px;
  }
  .orderContainer {
    margin-top: 20px;
  }
  .radioBar {
    width: 300px;
    height: 0px;
    opacity: 0.1;
    border: 0.5px solid #000000;
  }
  .btnContainer {
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: space-between;
    margin-left: -4%;
    margin-top: -2%;
  }
  .header {
    font-family: "Nunito Sans";
    font-size: 21px;
    font-weight: 700;
    color: #414042;
  }
  .sub-header {
    font-family: "Nunito Sans";
    font-size: 13px;
    font-weight: 400;
    opacity: 0.7;
    color: #9ca3ad;
    margin-top: 1px;
  }
  .commentField {
    width: 94%;
    border-bottom: 2px solid #e6e6e6;
    border-radius: 0px;
  }
  .commentField:focus {
    width: 94%;
    border-bottom: 2px solid #e6e6e6;
    border-radius: 0px;
  }
  .nextBtn {
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: center;
    margin-top: 5%;
  }
  .cardView {
    width: 90%;
    height: auto;
    background: #fff;
    box-shadow: 3px 2px 9px 0 rgba(0, 0, 0, 0.08);
    border-radius: 7px;
    padding: 5px 5px;
    text-align: center;
    border: 1px solid #e5e5e5;
  }
  .card {
    width: 95%;
    height: auto;
    background: #fff;
    box-shadow: 3px 2px 9px 0 rgba(0, 0, 0, 0.08);
    border-radius: 7px;
    padding: 20px 20px;
    text-align: center;
    margin-top: 25px;
    margin-left: 6px;
    border: 1px solid #e5e5e5;
  }
  .card-1 {
    width: 95%;
    height: auto;
    background: #fff;
    padding: 20px 20px;
    text-align: left;
    margin-top: 10px;
    margin-left: 6px;
    color: #414043;
    opacity: 0.8;
  }
  .container-1 {
    width: 90%;
    background-color: white;
    text-align: center;
    margin-top: 1%;
    padding-bottom: 15px;
  }
  .container-2 {
    width: 90%;
    background-color: white;
    text-align: center;
    margin-top: 25%;
    margin-bottom: 20px;
  }
  .container-3 {
    width: 342px;
    height: 237px;
    background-color: white;
    text-align: center;
    margin-top: 10%;
    margin-bottom: 20px;
  }

  .bar {
    width: 280px;
    height: 0px;
    border: 0.5px solid #b7d340;
    margin: 0 auto;
    margin-top: 20px;
  }
  .progressBar {
    height: 11.82px;
    background-color: #b7d340;
    color: white;
    padding: 1%;
    text-align: right;
    font-size: 20px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 11.8238px;
  }
  .userCard {
    width: 100%;
    height: 200px;
    border-radius: 8px;
    margin-top: 15px;
  }
  .genderContainer {
    width: 90px;
    height: 55px;
    margin-top: 32px;
    margin-right: 9px;
    margin-left: -35px;
  }
  .progressTagContainer {
    display: flex;
    justify-content: space-between;
    width: 100px;
  }
  .userContainer {
    width: 108%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    margin-top: -25px;
    margin-left: -16px;
    padding: 15px 15px;
  }
  .rootCauseContainer {
    width: 100%;
    height: 145px;
    padding: 6px;
  }
  .userCardbar {
    width: 114%;
    height: 0px;
    border: 0.2px solid #ebf1e2;
    margin-left: -20px;
    margin-top: 8%;
    margin-bottom: 4%;
  }
  .userDetailBox {
    width: 100%;
    height: 225px;
    border-radius: 9px;
    margin-top: 15px;
    border: 1px solid #b7d340;
  }
  .diagnosisContainer {
    width: 100%;
    height: 135px;
    padding: 20px;
    border-radius: 0px 0px 9px 9px;
  }
  .diagnosisBar {
    width: 315px;
    height: 0px;
    border: 0.2px solid #ebf1e2;
    margin-left: -20px;
    margin-top: 8%;
  }
  .diagnosis-1 {
    font-family: Nunito Sans, sans-serif;
    font-style: normal;
    font-weight: 400;
    width: 110px;
    height: 30px;
    color: #414042;
    opacity: 0.7;
    margin-left: -6px;
    margin-top: -4%;
    text-align: left;
  }
  .diagnosis-2 {
    font-family: Nunito Sans, sans-serif;
    font-style: normal;
    font-weight: 400;
    width: 110px;
    height: 30px;
    color: #414042;
    opacity: 0.7;
    margin-left: -8px;
    margin-top: 5%;
    text-align: left;
  }
  .diagnosisDetails-1 {
    font-family: Nunito Sans, sans-serif;
    width: 300px;
    height: 21px;
    color: #000000;
    margin-top: -4%;
    margin-left: 20px;
    text-align: left;
  }
  .diagnosisDetails-2 {
    font-family: Nunito Sans, sans-serif;
    width: 300px;
    height: 21px;
    color: #000000;
    margin-top: 6%;
    margin-left: 17px;
    text-align: left;
  }
  .diagnosisBar-1 {
    width: 0px;
    height: 32px;
    margin-left: 20px;
    margin-top: -2%;
    border: 0.5px solid #ebf1e2;
  }
  .diagnosisBar-2 {
    width: 0px;
    height: 50px;
    margin-left: 21px;
    margin-top: 7%;
    border: 0.5px solid #ebf1e2;
  }
  .diagnosisBarFinal-1{
    width: 0px;
    height: 36px;
    margin-left: 20px;
    margin-top: 0%;
    border: 0.5px solid #ebf1e2;
  }
  .diagnosisBarFinal-2{
    width: 0px;
    height: 40px;
    margin-left: 21px;
    margin-top: 6%;
    border: 0.5px solid #ebf1e2;
  }
  .userContainer-1 {
    width: 100%;
    height: 60px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }

  .progressBarText {
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: #414043;
    margin-left: 17px;
  }
  .cardView {
    width: 90%;
    height: auto;
    background: #fff;
    box-shadow: 3px 2px 9px 0 rgba(0, 0, 0, 0.08);
    border-radius: 7px;
    padding: 20px 15px;
    text-align: center;
    border: 1px solid #e5e5e5;
  }
  .slotBtnText {
    font-size: 0.8rem;
    line-height: 1.75rem;
    font-weight: 700;
  }
  .mainContainer {
    width: 90%;
    height: auto;
    margin: 0 auto;
  }
  .finalBtn {
    // position: fixed;
    // bottom: 0;
    width: 99%;
    height: 45px;
    // margin-left: -37%;
    background-color: #b7d340;
    font-size: 18px;
    color: white;
    font-weight: 600;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .finalBtn:hover,
  .finalBtn:visited,
  .finalBtn:active,
  .finalBtn:link {
    background-color: #b7d340;
    color: white;
  }

  .bookingBtn {
    width: 97%;
    height: 45px;
    margin-top: 20px;
    margin-bottom: 15px;
    background-color: #b7d340;
    font-size: 18px;
    color: white;
    font-weight: 600;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .bookingBtn:hover,
  .bookingBtn:visited,
  .bookingBtn:active,
  .bookingBtn:link {
    background-color: #b7d340;
    color: white;
  }
  .resultBtn {
    font-size: 20px;
    border-radius: 5px;
    box-shadow: none;
    width:300px;
    height: 45px;
  }
  .previousBtn {
    background-color: white;
    border: none;
    cursor: pointer;
    box-shadow: none;
  }
  .container {
    width: 97%;
    background-color: white;
    text-align: center;
    margin-top: 25%;
  }
}

@media (min-width: 1280px) {
  .ant-form label {
    width: 100%;
    font-size: 20px;
    color: #000;
    font-weight: 400;
  }
  .activeTag {
    width: 470px;
    height: 70px;
    margin-left: 25px;
    font-size: 18px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    padding: 22px 12px;
    background: @primary-color;
    color: #fcfcfc;
    border-radius: 9px;
    cursor: pointer;
  }

  .inactiveTag {
    width: 470px;
    height: 70px;
    margin-left: 25px;
    font-size: 18px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    padding: 22px 12px;
    cursor: pointer;
    color: #73767d;
    background: @disable-color;
    border-radius: 9px;
  }
  .progressTagContainer-1 {
    display: flex;
    justify-content: space-between;
    width: 220px;
    margin-left: 27px;
  }
  .activeTag-1 {
    width: 220px;
    font-size: 22px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    padding: 3.5px;
    background: @primary-color;
    color: #fcfcfc;
    border-radius: 9px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .inactiveTag-1 {
    width: 220px;
    font-size: 22px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    padding: 3.5px;
    cursor: pointer;
    color: #73767d;
    background: @disable-color;
    border-radius: 9px;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .progressTagContainer-2 {
    display: flex;
    justify-content: space-between;
    width: 220px;
    margin-left: 275px;
  }
  .activeTag-2 {
    width: 220px;
    font-size: 22px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    padding: 3.5px;
    background: @primary-color;
    color: #fcfcfc;
    border-radius: 9px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .inactiveTag-2 {
    width: 220px;
    font-size: 22px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    padding: 3.5px;
    cursor: pointer;
    color: #73767d;
    background: @disable-color;
    border-radius: 9px;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .progressTagContainer-3 {
    display: flex;
    justify-content: space-between;
    width: 220px;
    margin-left: 522px;
  }
  .activeTag-3 {
    width: 220px;
    font-size: 22px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    padding: 3.5px;
    background: @primary-color;
    color: #fcfcfc;
    border-radius: 9px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .inactiveTag-3 {
    width: 220px;
    font-size: 22px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    padding: 3.5px;
    cursor: pointer;
    color: #73767d;
    background: @disable-color;
    border-radius: 9px;
    margin-bottom: 20px;
    margin-right: 10px;
  }

  .tagContainer {
    width: 100%;
    height: auto;
    background: #fff;
    border-radius: 7px;
    padding: 0px 20px;
    // margin-left: 23px;
  }
  .introPageContainer {
    width: 717px;
    height: auto;
    background: #fff;
    padding: 0px 58px;
    margin-left: 22px;
  }
  .trayalogo {
    margin-top: 10px;
    margin-left: 20px;
    padding: 20px;
  }
  .userDetailContainer {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 70vh;
    background-color: white;
    text-align: center;
    margin-top: 75px;
    padding-top: 25px;
  }
  .orderContainer {
    margin-top: 38px;
  }
  .radioBar {
    width: 450px;
    height: 0px;
    opacity: 0.1;
    border: 0.5px solid #000000;
  }
  .btnContainer {
    display: flex;
    align-items: flex-end;
    width: 73%;
    justify-content: space-between;
    margin-left: 0%;
    margin-top: -3%;
  }
  .commentField {
    width: 75%;
    border-bottom: 2px solid #e6e6e6;
    border-radius: 0px;
  }
  .commentField:focus {
    width: 75%;
    border-bottom: 2px solid #e6e6e6;
    border-radius: 0px;
  }
  .nextBtn {
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: center;
    margin-top: 2%;
  }
  .header {
    font-family: "Nunito Sans";
    font-size: 25px;
    font-weight: 700;
    color: #414042;
  }
  .sub-header {
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 400;
    opacity: 0.7;
    color: #9ca3ad;
    margin-top: 1px;
  }
  .container {
    width: 60%;
    background-color: white;
    text-align: center;
    margin-top: 7%;
  }
  .container-4 {
    width: 60%;
    background-color: white;
    text-align: center;
    margin-top: 7%;
  }
  .bar {
    width: 550px;
    height: 0px;
    border: 0.5px solid #b7d340;
    // margin-left: 12%;
    margin: 0 auto;
    margin-top: 30px;
  }
  .card {
    width: 95%;
    height: auto;
    background: #fff;
    box-shadow: 3px 2px 9px 0 rgba(0, 0, 0, 0.08);
    border-radius: 7px;
    padding: 20px 20px;
    text-align: left;
    margin-top: 25px;
    margin-left: 22px;
    border: 1px solid #e5e5e5;
  }
  .card-1 {
    width: 75%;
    height: auto;
    background: #fff;
    padding: 20px 20px;
    text-align: left;
    margin-top: 10px;
    margin-left: 118px;
    color: #414043;
    opacity: 0.8;
  }
  .container-1 {
    width: 60%;
    background-color: white;
    text-align: center;
    margin-top: 1%;
    margin-bottom: 20px;
  }
  .container-2 {
    width: 60%;
    background-color: white;
    text-align: center;
    margin-top: 6%;
    margin-bottom: 20px;
  }
  .container-3 {
    width: 60%;
    height: 183px;
    background-color: white;
    text-align: center;
    margin-top: 105px;
    margin-bottom: 20px;
    border-radius: 9px;
  }
  .progressBarContainer {
    background-color: #e2e2e2;
    width: 540px;
    height: 12px;
    border-radius: 11px;
    margin-left: 95px;
    margin-top: 5px;
  }
  .progressBar {
    height: 11.82px;
    background-color: #b7d340;
    color: white;
    padding: 1%;
    text-align: right;
    font-size: 20px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 11.8238px;
  }
  .userCard {
    width: 100%;
    height: 154px;
    border-radius: 8px;
    margin-top: 15px;
  }
  .genderContainer {
    width: 90px;
    height: 55px;
    margin-top: 32px;
    margin-right: 9px;
    margin-left: 500px;
  }
  .userContainer {
    width: 100%;
    height: 60px;
    margin-top: -35px;
    padding: 25px 0px;
    display: flex;
    justify-content: space-between;
  }
  .rootCauseContainer {
    width: 100%;
    height: 104px;
    padding: 8px;
  }
  .userCardbar {
    width: 105.5%;
    height: 0px;
    border: 0.2px solid #ebf1e2;
    margin-left: -20px;
    margin-top: 5px;
  }
  .userDetailBox {
    width: 600px;
    height: 175px;
    border-radius: 9px;
    border: 1px solid #b7d340;
  }
  .diagnosisContainer {
    width: 596.5px;
    height: 120px;
    padding: 20px;
    border-radius: 0px 0px 9px 9px;
  }
  .diagnosisBar {
    width: 596px;
    height: 0px;
    border: 0.2px solid #ebf1e2;
    margin-left: -20px;
    margin-top: 1%;
  }
  .diagnosis-1 {
    font-family: Nunito Sans, sans-serif;
    font-style: normal;
    font-weight: 400;
    width: 110px;
    height: 30px;
    color: #414042;
    opacity: 0.7;
    margin-left: -6px;
    margin-top: -1%;
    text-align: left;
  }
  .diagnosis-2 {
    font-family: Nunito Sans, sans-serif;
    font-style: normal;
    font-weight: 400;
    width: 110px;
    height: 30px;
    color: #414042;
    opacity: 0.7;
    margin-left: -8px;
    margin-top: 2%;
    text-align: left;
  }
  .diagnosisDetails-1 {
    font-family: Nunito Sans, sans-serif;
    width: 300px;
    height: 21px;
    color: #000000;
    margin-top: -3%;
    margin-left: 20px;
    text-align: left;
  }
  .diagnosisDetails-2 {
    font-family: Nunito Sans, sans-serif;
    width: 300px;
    height: 21px;
    color: #000000;
    margin-top: 3%;
    margin-left: 17px;
    text-align: left;
  }
  .diagnosisBar-1 {
    width: 0px;
    height: 32px;
    margin-left: 20px;
    margin-top: -2%;
    border: 0.5px solid #ebf1e2;
  }
  .diagnosisBar-2 {
    width: 0px;
    height: 33px;
    margin-left: 21px;
    margin-top: 3%;
    border: 0.5px solid #ebf1e2;
  }
  
  .diagnosisBarFinal-1{
    width: 0px;
    height: 36px;
    margin-left: 20px;
    margin-top: 0%;
    border: 0.5px solid #ebf1e2;
  }
  .diagnosisBarFinal-2{
    width: 0px;
    height: 33px;
    margin-left: 21px;
    margin-top: 2%;
    border: 0.5px solid #ebf1e2;
  }
  .userContainer-1 {
    width: 592px;
    height: 60px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }

  .progressBarText {
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: #414043;
    margin-left: 95px;
  }

  .cardView {
    width: 50%;
    height: auto;
    background: #fff;
    box-shadow: 3px 2px 9px 0 rgba(0, 0, 0, 0.08);
    border-radius: 7px;
    padding: 20px 10px;
    text-align: center;
    border: 1px solid #e5e5e5;
  }
  .slotBtnText {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 700;
  }
  .mainContainer {
    width: 50%;
    height: auto;
    margin: 0 auto;
  }
  .finalBtn {
    // position: fixed;
    // bottom: 0;
    width: 60%;
    height: 45px;
    // margin-left: -12%;
    background-color: #b7d340;
    font-size: 18px;
    color: white;
    font-weight: 600;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .finalBtn:hover,
  .finalBtn:visited,
  .finalBtn:active,
  .finalBtn:link {
    background-color: #b7d340;
    color: white;
  }

  .bookingBtn {
    width: 40%;
    height: 45px;
    margin-top: 20px;
    margin-bottom: 15px;
    background-color: #b7d340;
    font-size: 18px;
    color: white;
    font-weight: 600;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .bookingBtn:hover,
  .bookingBtn:visited,
  .bookingBtn:active,
  .bookingBtn:link {
    background-color: #b7d340;
    color: white;
  }
  .resultBtn {
    font-size: 20px;
    border-radius: 5px;
    box-shadow: none;
    width:300px;
    height: 45px;
  }
  .previousBtn {
    background-color: white;
    border: none;
    cursor: pointer;
    box-shadow: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .container-4 {
    width: 60%;
    background-color: white;
    text-align: center;
    margin-top: 15%;
  }
  .ant-form label {
    width: 100%;
    font-size: 20px;
    color: #000;
    font-weight: 400;
  }
  .activeTag {
    width: 240px;
    height: 70px;
    margin-left: 25px;
    font-size: 16px;
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    padding: 24px 12px;
    background: @primary-color;
    color: #fcfcfc;
    border-radius: 9px;
    cursor: pointer;
  }

  .inactiveTag {
    width: 240px;
    height: 70px;
    margin-left: 25px;
    font-size: 16px;
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    padding: 24px 12px;
    cursor: pointer;
    color: #73767d;
    background: @disable-color;
    border-radius: 9px;
  }

  .progressTagContainer-1 {
    display: flex;
    justify-content: space-between;
    width: 165px;
    margin-left: 5%;
  }
  .activeTag-1 {
    width: 170px;
    font-size: 22px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    padding: 3.5px;
    background: @primary-color;
    color: #fcfcfc;
    border-radius: 9px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .inactiveTag-1 {
    width: 170px;
    font-size: 22px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    padding: 3.5px;
    cursor: pointer;
    color: #73767d;
    background: @disable-color;
    border-radius: 9px;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .progressTagContainer-2 {
    display: flex;
    justify-content: space-between;
    width: 165px;
    margin-left: 38.5%;
  }
  .activeTag-2 {
    width: 170px;
    font-size: 22px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    padding: 3.5px;
    background: @primary-color;
    color: #fcfcfc;
    border-radius: 9px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .inactiveTag-2 {
    width: 170px;
    font-size: 22px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    padding: 3.5px;
    cursor: pointer;
    color: #73767d;
    background: @disable-color;
    border-radius: 9px;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .progressTagContainer-3 {
    display: flex;
    justify-content: space-between;
    width: 165px;
    margin-left: 72%;
  }
  .activeTag-3 {
    width: 170px;
    font-size: 22px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    padding: 3.5px;
    background: @primary-color;
    color: #fcfcfc;
    border-radius: 9px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .inactiveTag-3 {
    width: 170px;
    font-size: 22px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    padding: 3.5px;
    cursor: pointer;
    color: #73767d;
    background: @disable-color;
    border-radius: 9px;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .tagContainer {
    width: 565px;
    height: auto;
    background: #fff;
    border-radius: 7px;
    padding: 0px 30px;
    margin-left: 20px;
  }
  .introPageContainer {
    width: 565px;
    height: auto;
    background: #fff;
    border-radius: 7px;
    padding: 0px 58px;
    margin-left: 22px;
  }
  .trayalogo {
    margin-top: 10px;
    margin-left: 20px;
    padding: 20px;
  }
  .userDetailContainer {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 60vh;
    background-color: white;
    text-align: center;
    margin-top: 75px;
    padding-top: 25px;
  }
  .orderContainer {
    margin-top: 38px;
  }
  .commentField {
    width: 86%;
    border-bottom: 2px solid #e6e6e6;
    border-radius: 0px;
  }
  .commentField:focus {
    width: 86%;
    border-bottom: 2px solid #e6e6e6;
    border-radius: 0px;
  }
  .btnContainer {
    display: flex;
    align-items: flex-end;
    width: 84%;
    justify-content: space-between;
    margin-left: 2%;
    margin-top: -3%;
  }
 

  .nextBtn {
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: center;
    margin-top: 2%;
  }
  .card-1 {
    width: 95%;
    height: auto;
    background: #fff;
    padding: 20px 20px;
    text-align: left;
    margin-top: 10px;
    margin-left: 6px;
    color: #414043;
    opacity: 0.8;
  }
  .bar {
    width: 465px;
    height: 0px;
    border: 0.5px solid #b7d340;
    // margin-left: 7%;
    margin: 0 auto;
    margin-top: 30px;
  }
  .card {
    width: 85%;
    height: auto;
    background: #fff;
    box-shadow: 3px 2px 9px 0 rgba(0, 0, 0, 0.08);
    border-radius: 7px;
    padding: 5px 5px;
    text-align: center;
    margin: 15px auto;
    border: 1px solid #e5e5e5;
  }
  .rootCauseContainer {
    width: 100%;
    height: 120px;
    margin-top: 10px;
    padding: 20px;
  }
  .container-3 {
    width: 342px;
    height: 237px;
    background-color: white;
    text-align: center;
    margin-top: 105px;
    margin-bottom: 20px;
  }

  .container-1 {
    width: 75%;
    background-color: white;
    text-align: center;
    margin-top: 12%;
    padding-bottom: 15px;
  }
  
  .container-2 {
    width: 75%;
    background-color: white;
    text-align: center;
    margin-top: 12%;
    margin-bottom: 20px;
  }
  .userCardbar {
    width: 105%;
    height: 0px;
    border: 0.2px solid #ebf1e2;
    margin-left: -20px;
    margin-top: 15px;
  }
  .cardView {
    width: 75%;
    height: auto;
    background: #fff;
    box-shadow: 3px 2px 9px 0 rgba(0, 0, 0, 0.08);
    border-radius: 7px;
    padding: 10px 15px;
    text-align: center;
    border: 1px solid #e5e5e5;
  }
  .userContainer {
    width: 100%;
    height: 60px;
    margin-top: 5px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }
  .progressBarContainer {
    background-color: #e2e2e2;
    width: 510px;
    height: 12px;
    border-radius: 11px;
    margin-left: 45px;
    margin-top: 5px;
  }
  .progressBar {
    height: 11.82px;
    background-color: #b7d340;
    color: white;
    padding: 1%;
    text-align: right;
    font-size: 20px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 11.8238px;
  }
  .progressBarText {
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: #414043;
    margin-left: 45px;
  }
  .userDetailBox {
    width: 430px;
    height: 177px;
    border-radius: 9px;
    border: 1px solid #b7d340;
  }
  .mainContainer {
    width: 72%;
    height: auto;
    margin: 0 auto;
  }
  .finalBtn {
    width: 70%;
    height: 45px;
    background-color: #b7d340;
    font-size: 18px;
    color: white;
    font-weight: 600;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .finalBtn:hover,
  .finalBtn:visited,
  .finalBtn:active,
  .finalBtn:link {
    background-color: #b7d340;
    color: white;
  }

  .bookingBtn {
    width: 55%;
    height: 45px;
    margin-top: 20px;
    margin-bottom: 15px;
    background-color: #b7d340;
    font-size: 18px;
    color: white;
    font-weight: 600;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .bookingBtn:hover,
  .bookingBtn:visited,
  .bookingBtn:active,
  .bookingBtn:link {
    background-color: #b7d340;
    color: white;
  }
  .resultBtn {
    font-size: 20px;
    border-radius: 5px;
    box-shadow: none;
    width:300px;
    height: 45px;
  }
  .previousBtn {
    background-color: white;
    border: none;
    cursor: pointer;
    box-shadow: none;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .container-4 {
    width: 60%;
    background-color: white;
    text-align: center;
    margin-top: 10%;
  }
  .activeTag {
    width: 400px;
    height: 70px;
    margin-left: 5px;
    font-size: 18px;
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    padding: 24px 12px;
    background: @primary-color;
    color: #fcfcfc;
    border-radius: 9px;
    cursor: pointer;
  }

  .inactiveTag {
    width: 400px;
    height: 70px;
    margin-left: 25px;
    font-size: 18px;
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    padding: 24px 12px;
    cursor: pointer;
    color: #73767d;
    background: @disable-color;
    border-radius: 9px;
  }
  .progressTagContainer-1 {
    display: flex;
    justify-content: space-between;
    width: 195px;
    margin-left: 7px;
  }
  .activeTag-1 {
    width: 185px;
    font-size: 22px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    padding: 3.5px;
    background: @primary-color;
    color: #fcfcfc;
    border-radius: 9px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .inactiveTag-1 {
    width: 185px;
    font-size: 22px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    padding: 3.5px;
    cursor: pointer;
    color: #73767d;
    background: @disable-color;
    border-radius: 9px;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .progressTagContainer-2 {
    display: flex;
    justify-content: space-between;
    width: 195px;
    margin-left: 222px;
  }
  .activeTag-2 {
    width: 185px;
    font-size: 22px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    padding: 3.5px;
    background: @primary-color;
    color: #fcfcfc;
    border-radius: 9px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .inactiveTag-2 {
    width: 185px;
    font-size: 22px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    padding: 3.5px;
    cursor: pointer;
    color: #73767d;
    background: @disable-color;
    border-radius: 9px;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .progressTagContainer-3 {
    display: flex;
    justify-content: space-between;
    width: 195px;
    margin-left: 446px;
  }
  .activeTag-3 {
    width: 185px;
    font-size: 22px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    padding: 3.5px;
    background: @primary-color;
    color: #fcfcfc;
    border-radius: 9px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .inactiveTag-3 {
    width: 185px;
    font-size: 22px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    padding: 3.5px;
    cursor: pointer;
    color: #73767d;
    background: @disable-color;
    border-radius: 9px;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .trayalogo {
    margin-top: 10px;
    margin-left: 20px;
    padding: 20px;
  }
  .bar {
    width: 520px;
    height: 0px;
    border: 0.5px solid #b7d340;
    // margin-left: 9%;
    margin: 0 auto;
    margin-top: 30px;
  }
  .userDetailContainer {
    width: 100%;
    height: 60vh;
    background-color: white;
    text-align: center;
    margin-top: 75px;
    padding-top: 25px;
  }
  .orderContainer {
    margin-top: 38px;
  }
  .commentField {
    width: 75%;
    border-bottom: 2px solid #e6e6e6;
    border-radius: 0px;
  }
  .commentField:focus {
    width: 75%;
    border-bottom: 2px solid #e6e6e6;
    border-radius: 0px;
  }
  .nextBtn {
    display: flex;
    align-items: flex-end;
    width: 90%;
    justify-content: center;
    margin-top: 2%;
  }
  .btnContainer {
    display: flex;
    align-items: flex-end;
    width: 70%;
    justify-content: space-between;
    margin-left: 5%;
    margin-top: -3%;
  }
  .progressBarContainer {
    background-color: #e2e2e2;
    width: 500px;
    height: 12px;
    border-radius: 11px;
    margin-left: 9%;
    margin-top: 5px;
  }
  .progressBar {
    height: 11.82px;
    background-color: #b7d340;
    color: white;
    padding: 1%;
    text-align: right;
    font-size: 20px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 11.8238px;
  }
  .finalBtn {
    // position: fixed;
    // bottom: 0;
    width: 60%;
    height: 45px;
    // margin-left: -16%;
    background-color: #b7d340;
    font-size: 18px;
    color: white;
    font-weight: 600;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .finalBtn:hover,
  .finalBtn:visited,
  .finalBtn:active,
  .finalBtn:link {
    background-color: #b7d340;
    color: white;
  }

  .bookingBtn {
    width: 45%;
    height: 45px;
    margin-top: 20px;
    margin-bottom: 15px;
    background-color: #b7d340;
    font-size: 18px;
    color: white;
    font-weight: 600;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .bookingBtn:hover,
  .bookingBtn:visited,
  .bookingBtn:active,
  .bookingBtn:link {
    background-color: #b7d340;
    color: white;
  }
  .resultBtn {
    font-size: 20px;
    border-radius: 5px;
    box-shadow: none;
    width:300px;
    height: 45px;
  }
  .previousBtn {
    background-color: white;
    border: none;
    cursor: pointer;
    box-shadow: none;
  }
}
